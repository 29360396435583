// import { useEffect, useRef } from 'react';
// import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts';

function formatCurrency(amount, currencyCode='USD', decimals=0) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: decimals
    });
    return formatter.format(amount);
}

const AmortizationChart = ({ data }) => {
    
    const chartRows = data.values;

    return (
        <div className="chart-container">
            <table className="chart">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Principal</th>
                        <th>Interest</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <div class="tbody-wrapper">
                  <div class="tbody-container">
                    <tbody>
                        {chartRows.map((row, index) => (
                            <tr key={index}>
                                <td>{row.month}</td>
                                <td>{formatCurrency(row.principal)}</td>
                                <td>{formatCurrency(row.interest)}</td>
                                <td>{formatCurrency(row.balance)}</td>
                            </tr>
                        ))}
                    </tbody>
                  </div>
                  <div class="shadow-overlay"></div>
                </div>
            </table>
        </div>
    );
};

export default AmortizationChart;