import db from "../lib/db";
import Modal from "./Modal";
import { useState, useEffect } from "react";

const InformationButton = () => {
    const [showModal, setShowModal] = useState(false);
    const modalVersion = '41624';

    const handleCloseModal = () => {
        setShowModal(false);
        localStorage.setItem('modalVersionSeen', modalVersion);
    };
    
    const handleShowModal = () => {
        setShowModal(true);
    };

    useEffect(() => {
        const modalVersionSeen = localStorage.getItem('modalVersionSeen');
        if (modalVersionSeen != modalVersion) {
            setShowModal(true);
        }
    }, []);


    return (
        <>
            <button className="information-button right" onClick={handleShowModal}></button>
            
            {showModal && (
                <Modal handleCloseModal={handleCloseModal} size="large">
                    <h1>What can Amorti do?</h1>
                    <p>As a sophisticated <strong>natural language model</strong>, I am <strong>fine-tuned</strong> based on the <strong>BERT</strong> architecture. My specialized training enables me to perform intricate loan calculations with ease.</p>
                    <p>Once you have provided your loan details:</p>
                    <ul>
                        <li>Ask me: "what if I change my monthly payment to $2k?"</li>
                        <li>Ask me: "show me an amortization chart"</li>
                        <li>Ask me: "can I see that as a graph?"</li>
                        <li>Ask me: "how do I pay off my loan in 10 years?"</li>
                        <li className="new-badge">Tip: You can now calculate loans that you are considering</li>
                    </ul>
                    <p><em>The information provided by Amorti is for general informational purposes only. Please consult with a financial professional before making any decisions based on the calculations and information provided.</em></p>
                    <div className="button-container">
                        <button className="close-modal button green" onClick={handleCloseModal}>Let's get started!</button>
                        <button className="close-modal button secondary" onClick={handleCloseModal}>
                            Learn more
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h6"/>
                                <polyline points="15 3 21 3 21 9"/>
                                <line x1="10" y1="14" x2="21" y2="3"/>
                            </svg>
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default InformationButton;