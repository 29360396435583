import axios from '../lib/axios';

const apiBaseUrl = 'chat';

const next = async ({sessionID, prompt, utterance}) => {

	console.log('next', sessionID, prompt, utterance)

	try {
		const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));
		const requestPromise = axios.post(apiBaseUrl + '/next', {
			sessionID: sessionID,
			prompt: prompt.content,
			utterance: utterance.content
		});

		const [response] = await Promise.all([requestPromise, delayPromise]);

		return(response.data);
	} catch (err) {
		console.error(err);
		return(err);
	}
};

export {
	next
}