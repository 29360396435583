const initialPrompts = [
    {
        role: "amorti",
        context: "greeting",
        contentType: "text",
        content: "Hi! I'm Amorti, your AI loan assistant!",
    },
    {
        role: "amorti",
        context: "greeting",
        contentType: "text",
        content: "I can help you figure out how much of your monthly payment goes to principal, how long it will take you to pay off your loan, and make suggestions on ways to pay your loan down faster! 🤑",
    },
    {
        role: "amorti",
        context: "loanType",
        suggestions: ['I have a mortgage', 'I have an auto loan', 'I have a student loan'],
        contentType: "text",
        content: "What type of loan are we talking about today?",
    },
];

module.exports = {
    initialPrompts,
};