import React, { useEffect, useState, useRef } from 'react';
import AmortizationGraph from "./AmortizationGraph";
import AmortizationChart from "./AmortizationChart";

const Message = ({ message, index, array, handleEditActive, handleEditClick }) => {
    const messageRef = useRef(null);
    const placeholderRef = useRef(null);
    let chunkClass = '';
    const previousMessage = array[index - 1] || null;
    const nextMessage = array[index + 1] || null;

    if (message.role === previousMessage?.role && message.role === nextMessage?.role) {
        chunkClass = 'chunk-inner';
    } else if (message.role === previousMessage?.role && message.role !== nextMessage?.role) {
        chunkClass = 'chunk-end';
    } else if (message.role !== previousMessage?.role && message.role === nextMessage?.role) {
        chunkClass = 'chunk-start';
    } else {
        chunkClass = 'chunk-single';
    }

    return (
        <>
            <div
                ref={messageRef}
                className={`chat-message ${message.role} ${message.contentType} ${chunkClass} ${message.role === 'amorti' && previousMessage?.role === 'user' ? 'no-animation' : ''}`}
                messageid={message.id}
                // onMouseEnter={handleEditActive}
                // onMouseLeave={handleEditActive}
                // onTouchStart={handleEditActive}
                // onTouchEnd={handleEditActive}
            >
                {/* TODO: Add edit functionalit back */}
                {/* {message.role === 'user' && <div className="chat-message-edit-save" onClick={handleEditClick}>Edit</div>} */}

                {message.contentType === "text" && (
                    <span className="text-value" suppressContentEditableWarning={true} dangerouslySetInnerHTML={{__html: message.content}} />
                )}
                {message.contentType === "graph" && (
                    <AmortizationGraph data={message.content} />
                )}
                {message.contentType === "chart" && (
                    <AmortizationChart data={message.content} />
                )}
            </div>

            {message.role === "user" && nextMessage === null && (
                <div
                    ref={placeholderRef}
                    className={`chat-message amorti typing-message`}
                >
                    <div className="typing-dots">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            )}
        </>
    );
};

export default Message;
