import React, { useEffect, useRef } from "react";

const Modal = ({ handleCloseModal, size, children }) => {
    const modalRef = useRef(null);
    const modalDialogRef = useRef(null);

    const CloseIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 6L6 18"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 6L18 18"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const closeModal = () => {
        if (handleCloseModal) {
            handleCloseModal();
        } else {
            const modal = document.querySelector(".modal");
            const modalUnderlay = document.querySelector(".modal-underlay");
            modal.remove();
            modalUnderlay.remove();
        }
    };

    const handleClickOutside = (event) => {
        if (
            modalRef.current &&
            !modalDialogRef.current.contains(event.target)
        ) {
            closeModal();
        }
    };

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            if (modalRef.current) {
                modalRef.current.removeEventListener(
                    "mousedown",
                    handleClickOutside
                );
            }
        };
    }, []);

    return (
        <>
            <div className="modal" ref={modalRef}>
                <div className={`modal-dialog ${size}`} ref={modalDialogRef}>
                    <button className="close-icon" onClick={closeModal}>
                        <CloseIcon />
                    </button>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Modal;
