import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

function formatCurrency(amount, currencyCode='USD', decimals=0) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: decimals
    });
    return formatter.format(amount);
}

const AmortizationGraph = ({ data }) => {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="chart-tooltip">
                <p className="payment">Payment: {label}</p>
                <p className="principal color-green">{`Principal: ${formatCurrency(payload[0].value)}`}</p>
                <p className="interest color-purple">{`Interest: ${formatCurrency(payload[1].value)}`}</p>
                <p className="balance color-pink">{`Balance: ${formatCurrency(payload[2].value)}`}</p>
            </div>
          );
        }
      
        return null;
      };

    const renderLineChart = (
        <ResponsiveContainer>
            <LineChart data={data.values}>
                <Line type="monotone" dataKey="principal" stroke="#75ffd6" dot={false} strokeWidth={3} />
                <Line type="monotone" dataKey="interest" stroke="#8884d8" dot={false} strokeWidth={3} />
                <Line type="monotone" dataKey="balance" stroke="#ff1f9d" dot={false} strokeWidth={3} />
                <XAxis dataKey="month" type='number' domain={['dataMin', 'dataMax']} stroke="#525c69" />
                <Tooltip content={<CustomTooltip />} />
                {data.showLegend && <Legend align='left' />}
            </LineChart>
        </ResponsiveContainer>
      );

    return (
        <div className="graph-container">
            {renderLineChart}
        </div>
    );
};

export default AmortizationGraph;