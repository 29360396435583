import React, { useEffect, useRef, useState, useCallback } from "react";
import ScrollToBottom from 'react-scroll-to-bottom';
import { useLiveQuery } from "dexie-react-hooks";
import db from "../lib/db";
import { next } from "../api/chat";
import { initialPrompts} from "../helpers/constants";
import Message from "./Message";

const Chat = () => {
    const thread = useLiveQuery(() => db.messages.toArray(), []);
    const [inputType, setInputType] = useState('option');
    const [contextOptions, setContextOptions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const textInputRef = useRef(null);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    async function bulkAddMessages(items, delayTime) {
        for (const item of items) {
            await db.messages.add(item);
            await delay(delayTime);
        }
    }

    const initThread = useCallback(async () => {
        if (thread?.length === 0) {
            await bulkAddMessages(initialPrompts, 650);
        }
    });

    useEffect(() => {
        initThread();        
        if (thread) {
            const lastMessage = thread[thread.length - 1];

            // Handle suggestions
            if (lastMessage?.suggestions) {
                setSuggestions(lastMessage.suggestions);
            } else {
                setSuggestions([]);
            }

            // Set input focus
            if (textInputRef.current) {
                textInputRef.current.focus();
            }
        }
    }, [thread]);

    const submitMessage = async (event) => {
        const value = event.target.value || inputValue;
        if (textInputRef && textInputRef.current) {
            textInputRef.current.value = "";
        }
        await addToConversation(value);
    };

    const addToConversation = useCallback(async (inputValue) => {
        try {
            const lastMessage = await db.messages
                .orderBy("id")
                .reverse()
                .first();

            const newMessage = {
                role: "user",
                context: lastMessage ? lastMessage.context : "null",
                contentType: "text",
                content: inputValue,
            };

            await db.messages.add(newMessage);
            const conversation = await db.messages.toArray();
            const sessionID = localStorage.getItem('amortiSessionID') || null;
            const response = await next({
                sessionID: sessionID,
                prompt: conversation[conversation.length - 2],
                utterance: conversation[conversation.length - 1]
            });
            localStorage.setItem('amortiSessionID', response?.sessionID);
            const nextPrompts = response?.prompts;
            if (nextPrompts) {
                await bulkAddMessages(nextPrompts, 650);
            }
            
        } catch (error) {
            console.error(error);
        }

    }, []);

    const handleEditActive = (event) => {
        if (event.type === 'mouseenter' || event.type === 'touchend') {
            event.target.closest('.chat-message').classList.add('show-edit-save');
        } else if (event.type === 'mouseleave') {
            event.target.closest('.chat-message').classList.remove('show-edit-save');
        }
    };
    const handleEditClick = async (event) => {
        const messageElement = event.target.closest('.chat-message');
        const messageId = parseInt(messageElement.getAttribute('messageid'));
        const textValueElement = messageElement.querySelector('.text-value');
        const textValue = textValueElement.innerHTML;

        if (event.target.innerHTML === 'Edit') {
            messageElement.classList.add('is-editing');
            textValueElement.setAttribute('contenteditable', true);
            textValueElement.focus();
            window.getSelection().selectAllChildren(textValueElement);
            event.target.innerHTML='Save & Submit';
        } else {
            await db.messages.where('id').aboveOrEqual(messageId).delete(); // Delete all messages with id >= messageId
            await addToConversation(textValue); // Resubmit the edited message
            messageElement.querySelector('span').setAttribute('contenteditable', false);
            messageElement.classList.remove('is-editing');
            event.target.innerHTML = 'Edit';
        }
    };

    return (
        <>
            <div className="chat-thread-container">
                <ScrollToBottom className="chat-thread" scrollViewClassName="chat-thread-scroll-view">
                    {thread ? (
                        thread.map((message, index, array) => {
                            return (
                                <Message 
                                    message={message} 
                                    index={index} 
                                    array={array} 
                                    key={message.id} 
                                    // handleEditActive={handleEditActive} 
                                    // handleEditClick={handleEditClick}
                                />
                            );
                        })
                    ) : (
                        <>Loading...</>
                    )}
                </ScrollToBottom>
            </div>
            <div className={`chat-input-container input-type-${inputType}`}>
                    {/* Temporarily removing suggestions */}
                    {/* <div className="chat-input-option-container">
                        {suggestions && suggestions.map((option, index) => (
                            <div className="chat-input-option" key={index}>
                                <label htmlFor={`option-${index}`} tabIndex={index + 1} onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        document.getElementById(`option-${index}`).click();
                                    }
                                }}>
                                    <input
                                        type="radio"
                                        id={`option-${index}`}
                                        name="option"
                                        onChange={submitMessage}
                                        value={option}
                                        tabIndex="-1"
                                    />
                                    {option}
                                </label>
                            </div>
                        ))}
                    </div> */}
                    <div className="chat-input-text-container">
                        <input
                            type="text"
                            ref={textInputRef}
                            className="chat-input"
                            placeholder="Type your response here ..."
                            onChange={(event) => setInputValue(event.target.value)}
                            onKeyDown={(event) =>
                                event.key === "Enter" && submitMessage(event)
                            }
                        />
                        <button
                            className="chat-input-button"
                            onClick={submitMessage}
                        ></button>
                    </div>
            </div>
        </>
    );
};

export default Chat;
