import db from "../lib/db";
import Modal from "./Modal";
import { useState } from "react";

const ResetThreadButton = () => {
    const [showModal, setShowModal] = useState(false);

    const handleResetThread = () => {
        localStorage.removeItem('amortiSessionID');
        db.messages.clear();
        setShowModal(false);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
    };
    
    const handleShowModal = () => {
        setShowModal(true);
    };

    return (
        <>
            <button className="reset-thread-button left" onClick={handleShowModal}></button>
            
            {showModal && (
                <Modal handleCloseModal={handleCloseModal}>
                    <h1>Clear my memory?</h1>
                    <p>Resetting my memory will delete all personalized data and preferences. This action is irreversible.</p>
                    <p>Are you sure you want to proceed?</p>
                    <div className="button-container">
                        <button className="reset-button button pink" onClick={handleResetThread}>Reset</button>
                        <button className="close-modal button secondary" onClick={handleCloseModal}>Cancel</button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ResetThreadButton;