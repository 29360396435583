import React from 'react';
import Chat from "./components/Chat";

import './scss/styles.scss';
import logo from './assets/amorti-glitch-logo.png'
import ResetThreadButton from './components/ResetThreadButton';
import InformationButton from './components/InformationButton';

const App = () => {
  return (
    <>
      <header>
        <div className="header-inner">
          <ResetThreadButton />
          <img src={logo} alt="Amorti Logo" className="center" />
          <InformationButton />
        </div>
      </header>
      <Chat />
    </>
  );
};

export default App;
